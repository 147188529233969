namespace ute.datalayer.common.service {
  'use strict';

  /** Main service interface */
  export interface IDataLayerService {
    track(payload: ITrackDataPayload, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping): void;
    getMappingPayload(mapping: IDataLayerMapping): ITrackDataPayload;
    getConfigByMappingKey(mapping: IDataLayerMapping): any;
    registerCallbacks(callBacks: Array<IDataLayerCallback>): void;
    trackError(payload: ITrackDataPayload, errorType: ErrorType, errorCode: string, errorMessage: string, errorCategory: ErrorCategory, errorField: string, errorAPI: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[]): void;
    trackFormError(payload: ITrackDataPayload, errorMessage: string, errorField: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping): void;
    trackAPIError(errorCode: string, errorMessage: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping): void;
    trackErrors(payload: ITrackDataPayload, trackingErrors: IADLError[], isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[]): void;
  }

  export enum ErrorType {
    FORM,
    SELF_SERVE,
    HTTP,
    SIGNIN,
    LOCATION,
    CREDIT
  }

  export enum DataLayerPredefinedSection {
    ACCOUNT,
    PAGE,
    PAGE_VIEW_EVENT,
    SIGNIN,
    SITE,
    GLOBAL
  }

  export enum ErrorCategory {
    BUYFLOW,
    SELF_SERVE,
    LOGIN,
    VAS
  }

  export interface ITrackErrorParams {
    payload?: ITrackDataPayload;
    errorType?: ErrorType;
    errorCode?: string;
    errorMessage?: string;
    errorCategory?: ErrorCategory;
    errorField?: string;
    errorAPI?: string;
    isModalPopup?: boolean;
    selectedSections?: DataLayerPredefinedSection;
  }

  export interface IDataLayerTrackParams {
    payload?: ITrackDataPayload;
    mapping?: IDataLayerMapping;
  }

  export interface IDataLayerMapping {
    mappingKey: string;
    stateURL?: string;
  }

  export interface ITrackDataPayload {
    events?: IADLEvents;
    site?: IADLSite;
    page?: IADLPage;
    signin?: IADLSignin;
    article?: IADLArticle;
    account?: IADLAccount;
    ecommerce?: IADLEcommerce;
    buyflow?: IADLBuyflow;
    selfServe?: IADLSelfServe;
    errors?: IADLError[];
  }

  export interface IADLSite {
    name: string;
  }

  export interface IADLSignin {
    type: string;
  }

  export interface IADLEvents {
    addToCart?: boolean;
    articleHelp?: boolean;
    autopay?: boolean;
    buyFlowComplete?: boolean;
    buyFlowStart?: boolean;
    buyFlowStepView?: boolean;
    cartView?: boolean;
    checkout?: boolean;
    error?: boolean;
    interaction?: boolean;
    keepPlan?: boolean;
    linkClick?: boolean;
    liveChatOpen?: boolean;
    liveChatStart?: boolean;
    orderReview?: boolean;
    pageView?: boolean;
    paymentInfo?: boolean;
    productView?: boolean;
    purchase?: boolean;
    removeFromCart?: boolean;
    resumeCart?: boolean;
    saveCart?: boolean;
    search?: boolean;
    searchFilterAdd?: boolean;
    searchFilterRemove?: boolean;
    searchResultClick?: boolean;
    selfServeComplete?: boolean;
    selfServeStart?: boolean;
    serviceAvailCheck?: boolean;
    signinAttempt?: boolean;
    signinSuccess?: boolean;
    storeLocatorFail?: boolean;
    storeLocatorSuccess?: boolean;
  }

  export interface IADLPage {
    name?: string;
    hierarchy?: Array<string>;
    language?: string;
    province?: string;
    url?: string;
    referrer?: string;
    ICMTimestamp?: string;
    responsiveVersion?: string;
  }

  export interface IADLArticle {
    title: string;
    type: string;
    LOB: string;
    helpfulness: string;
    articleHelp: boolean;
    rating: string;
  }

  export interface IADLAccount {
    type?: string;
    userType?: string;
    userName?: string;
    BAN?: string;
    CTN?: string;
    agentType?: string;
    dealerCode?: string;
    wireless?: IADLWireless;
    internet?: IADLInternet;
    tv?: IADLTV;
  }

  export interface IADLWireless {
    device?: string;
    plan?: string;
    balance?: IADLBalance;
  }

  export interface IADLBalance {
    amount?: string;
    payoffDate?: string;
  }

  export interface IADLInternet {
    package: string;
  }

  export interface IADLTV {
    package: string;
  }

  export interface IADLEcommerce {
    transactionID?: string;
    currency?: string;
    tax?: string;
    province?: string;
    postal?: string;
    shipMethod?: string;
    shipAmount?: string;
    fulfillmentMethod?: string;
    paymentMethods?: Array<string>;
    fees?: Array<IADLFees>;
    promo?: Array<IADLPromo>;
    products?: Array<IADLProducts>;
  }

  export interface IADLFees {
    type: string;
    amount: string;
  }

  export interface IADLPromo {
    code: string;
    amount: string;
  }

  export interface IADLProducts {
    name: string;
    ID: string;
    category: string;
    subCategory: string;
    findingMethod: string;
    price?: string;
    MSF?: string;
    previousRevenue?: string;
    quantity: string;
  }

  export interface IADLBuyflow {
    name: string;
    stepName: string;
    stepNumber: string;
    serial: string;
  }

  export interface IADLSelfServe {
    type: string;
    name: string;
    revenue?: string;
    paymentMethod?: string;
    transactionID?: string;
  }

  export interface IADLSearch {
    keyword: string;
    type: string;
    resultCount: string;
  }

  export interface IADLError {
    type?: string;
    category?: string;
    message?: string;
    field?: string;
    code?: string;
    API?: string;
  }

  export interface IDataLayerServiceConfig {
    basic?: {
      site: {
        name: string;
      };
      signin: {
        type: string;
      };
    };
    advance?: {
      configOptions?: {
        rootHierarchy?: Array<string>;
        pathToConfigFile?: string;
        isAbsolutePath?: boolean;
        loadFromFile?: boolean;
        globalTrackingOn?: boolean;
      };
      mappingKey?: {
      };
    };
  }

  export type CallbackFunction = (...args: any[]) => any;

  export interface IDataLayerCallback {
    callbackKey: string;
    callback : CallbackFunction;
  }
  /** Main configuration interface */
  export interface IDataLayerServiceProvider extends ute.common.typescript.api.IServiceProvider<IDataLayerServiceConfig> {
  }
}
