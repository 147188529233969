namespace ute.datalayer.common.service {
  'use strict';

  interface IDataLayerAttributes extends ng.IAttributes {
    fireDataLayer: string;
    errorPayload: string;
    isModalPopup: string;
    mappingKey: string;
    formFieldName: string;
    pageView: string;
  }

  angular.module('ute.datalayer.common').directive('uteDataLayerFormFieldError', UteDataLayerFormFieldError);

  UteDataLayerFormFieldError.$inject = ['ute.datalayer.common.service', '$timeout', '$parse'];

  /* @ngInject */
  function UteDataLayerFormFieldError(dataLayerService: IDataLayerService, $timeout: ng.ITimeoutService, $parse: ng.IParseService) {
    return {
      restrict: 'A',
      link: function ($scope: ng.IScope, $element: ng.IRootElementService, attrs: IDataLayerAttributes): void {
        let fireDataLayer: boolean = $parse(attrs.fireDataLayer)($scope);
        if (!(fireDataLayer === false)) {
          $scope.$applyAsync(() => {
            try {
              let mapping: IDataLayerMapping, errorPayload, mappingKey, isModalPopup: boolean;
              let selectedSections: DataLayerPredefinedSection[]  = [
                DataLayerPredefinedSection.ACCOUNT,
                DataLayerPredefinedSection.PAGE,
                DataLayerPredefinedSection.SITE];

              if (attrs.errorPayload) {
                // try parse json object . In case when payload specified as a json object.
                try {
                  errorPayload = JSON.parse(attrs.errorPayload);
                } catch (e) {
                  //silent fail
                }

                // try parse bound variable ( scope or controller(as syntax)) when json parsing failed.
                if (!errorPayload) {
                  try {
                    errorPayload = $parse(attrs.errorPayload)($scope);
                  } catch (e) {
                    //silent fail
                  }
                }
              }
              isModalPopup = $parse(attrs.isModalPopup)($scope) || false;

              // if mappingKey is not bound to parent scope or controller variable, then tries to get mappingKey from attrs object
              try {
                mappingKey = $parse(attrs.mappingKey)($scope);
              } catch (e) {
                //silent fail
              }
              if (!mappingKey) {
                mappingKey = attrs.mappingKey;
              }

              if (mappingKey) {
                mapping = {
                  mappingKey: mappingKey
                };
              }
              if (attrs.pageView === 'true') {
                selectedSections.push(DataLayerPredefinedSection.PAGE_VIEW_EVENT);
              }

              dataLayerService.trackFormError(
                errorPayload || {},
                $element.text().trim(),
                attrs.formFieldName || '',
                isModalPopup,
                selectedSections,
                mapping);

            } catch (err) {
              console.warn('Exception in dataLayer form error directive', err);
            };
          });
        }
      }
    };
  }
}

