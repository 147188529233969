namespace ute.datalayer.common.service {
  'use strict';
  declare var _trackData: any;
  declare var moment: any;
  class DataLayerServiceProvider implements IDataLayerServiceProvider {

    private config: IDataLayerServiceConfig = {};
    private is_trackDataFunctionExist: boolean = (typeof (_trackData) === 'function');
    private configLoaded = false;
    private $q: ng.IQService;
    private $http: ng.IHttpService;
    private $window: ng.IWindowService;
    private $rootScope: ng.IRootScopeService;
    private registeredCallbacks: Array<IDataLayerCallback> = [];
    private referrer: string = '';

    constructor() {
      this.$get.$inject = ['$http', '$q', '$window', '$rootScope'];
    }

    $get: ($http: ng.IHttpService, $q: ng.IQService, $window: ng.IWindowService, $rootScope: ng.IRootScopeService) => IDataLayerService = ($http: ng.IHttpService, $q: ng.IQService, $window: ng.IWindowService, $rootScope: ng.IRootScopeService) => {
      var self = this;
      this.$http = $http;
      this.$q = $q;
      this.$window = $window;
      this.$rootScope = $rootScope;

      this.getConfig()
        .then(() => {
          if (_.has(this.config, 'advance.configOptions.globalTrackingOn')) {
            if (this.config.advance.configOptions.globalTrackingOn) {
              $rootScope.$on('$stateChangeSuccess', function (evt: any, toState: any) {
                setTimeout(function () {
                  self.trackGlobal(
                    {
                      mappingKey: toState.name,
                      stateURL: toState.url
                    });
                }, 0);
              });
            }
          }
        });

      return {
        track: this.track,
        trackGlobal: this.trackGlobal,
        getMappingPayload: this.getMappingPayload,
        getConfigByMappingKey: this.getConfigByMappingKey,
        registerCallbacks: this.registerCallbacks,
        trackError: this.trackError,
        trackFormError: this.trackFormError,
        trackErrors: this.trackErrors,
        trackAPIError: this.trackAPIError
      };
    };

    /**
     * @description To merge the passed in configuration with the local configuration
     * @param config: IDataLayerServiceConfig
     * @returns boolean
     */
    configure: (config: IDataLayerServiceConfig) => boolean = (config: IDataLayerServiceConfig) => {
      this.config = <IDataLayerServiceConfig>_.merge(this.config, config);
      return true;
    };

    /**
     * @description To register callbacks in common datalayer to obtain data that require app specific implementation
     * @param callBacks: Array<IDataLayerCallback>
     */
    private registerCallbacks = (callBacks: Array<IDataLayerCallback>) => {
      for (var i = 0; i < callBacks.length; i++) {
        this.registeredCallbacks.push(callBacks[i]);
      }
    };


    /**
     * @description To get configuration from  external source or from local object.
     * If property advance.configOptions.loadFromFile is defined in config and its value is true then it performs http.get call
     * to obtain configuration using path that defined in property advance.configOptions.pathToConfigFile. Otherwise resolves the promise.
     * @returns Promise for configuration data.
     */
    private getConfig: () => ng.IPromise<boolean> = () => {
      if (this.configLoaded) {
        return this.$q.when(true);
      } else {
        if (_.has(this.config, 'advance.configOptions.loadFromFile')) {
          if (this.config.advance.configOptions.loadFromFile) {
            let pathName = this.config.advance.configOptions.pathToConfigFile;
            if (!this.config.advance.configOptions.isAbsolutePath) {
              pathName = location.origin + location.pathname + pathName;
            }
            return this.$http.get(this.config.advance.configOptions.pathToConfigFile)
              .then((response) => {
                  this.configure(response.data);
                  this.configLoaded = true;
                  return true;
                }
              ).catch((error) => {
                console.warn('Initializing data layer config from file failed', error);
                return true;
              });

          } else {
            return this.$q.when(true);
          }
        } else {
          return this.$q.when(true);
        }
      }
    }

    private getGlobalSections = () => {
      let selectedSections: DataLayerPredefinedSection[] = [
        DataLayerPredefinedSection.ACCOUNT,
        DataLayerPredefinedSection.PAGE,
        DataLayerPredefinedSection.PAGE_VIEW_EVENT,
        DataLayerPredefinedSection.SITE
      ];
      return selectedSections;
    }
    /**
     * @description To pass all the required parameters to the track function in common service
     * @param mapping?: IDataLayerMapping
     */
    private trackGlobal = (mapping?: IDataLayerMapping) => {
      try {
        /* To make sure the tracking library has loaded and _trackData function exists */
        if (!this.is_trackDataFunctionExist) {
          return;
        }

        let selectedSections: DataLayerPredefinedSection[] = this.getGlobalSections();
        let datalayerTrackParams: IDataLayerTrackParams = {
          mapping: mapping
        };
        let currentPayload_cb = _.find(this.registeredCallbacks, {'callbackKey': 'payload'});
        let currentPayload_promise;
        let stateName;
        if (mapping) {
          stateName = mapping.mappingKey;
        }
        if (currentPayload_cb) {
          currentPayload_promise = currentPayload_cb.callback(stateName);
          this.$q.when(currentPayload_promise)
            .then((payLoadData) => {
              this.mergeSelectedInformation(payLoadData || {}, false, selectedSections)
                .then((payload: ITrackDataPayload) => {
                  datalayerTrackParams.payload = payload;
                  this._track(datalayerTrackParams);
                })
                .catch((error) => {
                  console.warn('trackGlobal -> mergeSelectedInformation: Error in merging payload data', error);
                });
            })
            .catch((error) => {
              console.warn('Error in resolving payload promise.');
            });
        } else {
          this.mergeSelectedInformation({}, false, selectedSections)
            .then((payload: ITrackDataPayload) => {
              datalayerTrackParams.payload = payload;
              this._track(datalayerTrackParams);
            })
            .catch((error) => {
              console.warn('trackGlobal -> mergeSelectedInformation: Error in merging payload data', error);
            });
        }
      } catch (error) {
        console.warn('trackGlobal -> Data layer global track', error);
      }
    }


    /**
     * @description To construct the payload object based on the passed in parameters.
     * @param params: IDataLayerTrackParams
     */
    private track: (payload: ITrackDataPayload, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping) => void =
      (payload: ITrackDataPayload, isModalPopup: boolean = false, selectedSections: DataLayerPredefinedSection[], mapping?: IDataLayerMapping) => {
        let datalayerTrackParams: IDataLayerTrackParams = {
          payload: payload,
          mapping: mapping
        };

        try {
          /* To make sure the tracking library has loaded and _trackData function exists */
          if (!this.is_trackDataFunctionExist) {
            return;
          }
          if (selectedSections !== null && selectedSections !== undefined && selectedSections.length > 0) {
            if (_.indexOf(selectedSections, DataLayerPredefinedSection.GLOBAL) >= 0) {
              selectedSections = this.getGlobalSections();
            }
            this.mergeSelectedInformation(payload, isModalPopup, selectedSections)
              .then((_payload: ITrackDataPayload) => {
                datalayerTrackParams.payload = _payload;
                this._track(datalayerTrackParams);
              })
              .catch((error) => {
                console.warn('track -> mergeSelectedInformation: Error in merging payload data', error);
              });
          } else {
            this._track(datalayerTrackParams);
          }
        } catch (error) {
          console.warn('Error in track function', error);
        }
      }


    /**
     * @description 1. To construct the payload object based on the passed in parameters.
     *              2. To obtain partial payload based on params.mapping and merge into final payload.
     *              3. To perform call to _trackData function with final payload object
     * @param params: Contains 2 properties => mapping and payload
     */
    private _track: (params: IDataLayerTrackParams) => void = (params: IDataLayerTrackParams) => {
      let mappingPayLoad: ITrackDataPayload;

      //To make sure the tracking library has loaded and _trackData is a function
      if (!this.is_trackDataFunctionExist) {
        return;
      }
      this.getConfig()
        .then(() => {
          if (params.mapping) {
            mappingPayLoad = this.getMappingPayload(params.mapping);
            if (mappingPayLoad) {
              _.merge(params.payload, mappingPayLoad);
            }
          }
          _trackData(params.payload);
        });
    }

    /**
     * @description To send the payload object with errors to the dataLayerTrack function from the data layer common service.
     * @param payload: ITrackDataPayload,
     *        trackingErrors: IADLError[],
     *        isModalPopup?: boolean, (default = false)
     *        selectedSections?: DataLayerPredefinedSection[],
     *        mapping?: IDataLayerMapping
     */
    private trackErrors: (payload: ITrackDataPayload, trackingErrors: IADLError[], isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[]) => void =
      (payload: ITrackDataPayload, trackingErrors: IADLError[], isModalPopup: boolean = false, selectedSections: DataLayerPredefinedSection[]) => {
        try {
          /* To make sure the tracking library has loaded and _trackData function exists */
          if (!this.is_trackDataFunctionExist) {
            return;
          }

          let errors: IADLError[] = trackingErrors;
          let event: IADLEvents = {error: true};

          /* To set Error Type to lowercase and Decode HTML entity for message */
          errors.map((err) => {
            err.type = err.type.replace('_', ' ').toLocaleLowerCase();
            err.message = angular.element('<textarea />').html(err.message).text();
            err.category = err.category.replace('_', ' ').toLocaleLowerCase();
          });

          let _payload: ITrackDataPayload = {
            errors: errors,
            events: event
          };

          if (angular.isObject(payload)) {
            _.merge(payload, _payload);
          }

          this.track(payload, isModalPopup, selectedSections);
        } catch (error) {
          console.warn('Data layer tracking errors', error);
        }
      }

    /**
     * @description
     * @param payload: ITrackDataPayload,
     *        errorType: ErrorType,
     *        errorCode: string,
     *        errorMessage: string,
     *        errorCategory: ErrorType,
     *        errorField: string,
     *        errorAPI: string,
     *        isModalPopup?: boolean, (default = false)
     *        selectedSections?: DataLayerPredefinedSection[],
     *        mapping?: IDataLayerMapping
     */
    private trackError: (payload: ITrackDataPayload, errorType: ErrorType, errorCode: string, errorMessage: string, errorCategory: ErrorCategory, errorField: string, errorAPI: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[]) => void =
      (payload: ITrackDataPayload, errorType: ErrorType, errorCode: string, errorMessage: string, errorCategory: ErrorCategory, errorField: string = '', errorAPI: string = '', isModalPopup: boolean = false, selectedSections: DataLayerPredefinedSection[]) => {
        try {
          /* To make sure the tracking library has loaded and _trackData function exists */
          if (!this.is_trackDataFunctionExist) { return; }

          let errors: IADLError[] = [];
          let error: IADLError = {
            type: ErrorType[errorType],
            code: errorCode,
            message: errorMessage,
            category: ErrorCategory[errorCategory],
            field: errorField,
            API: errorAPI
          };
          errors.push(error);
          this.trackErrors(payload, errors, isModalPopup, selectedSections);
        } catch (error) {
          console.warn('trackError method: ', error);
        }
      }

    /**
     * @description To track error on form field
     * @param payload: ITrackDataPayload, // if payload param is undefined then tryies to get payload from configuration
     *        errorMessage: string,
     *        errorField: string,
     *        isModalPopup?: boolean, (default = false)
     *        selectedSections?: DataLayerPredefinedSection[], if not specified then used all 4 sections
     *        mapping?: IDataLayerMapping
     */
    private trackFormError: (payload: ITrackDataPayload, errorMessage: string, errorField: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping) =>  void =
      (payload: ITrackDataPayload, errorMessage: string, errorField: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping) => {
        var error, errors = [];
        try {
          /* To make sure the tracking library has loaded and _trackData function exists */
          if (!this.is_trackDataFunctionExist) { return; }

          if (mapping && mapping.mappingKey) {
            let mappingKeys = mapping.mappingKey.split(',');
            //set error mapping keys
            let errorMappingKeysArr: string[] = _.filter(mappingKeys, function(keyItem: string) {
              return keyItem.indexOf('errors:') > -1;
            });
            error = this.getErrorData('', errorMessage, errorField, errorMappingKeysArr);
            // Merge value "FORM" to property "type" in error object. This value will always be used in tracking error
            // on form validation.
            if (error) {
              _.merge(error, {type: ErrorType[ErrorType.FORM]});
              errors.push(error);
            }
            //remove error mapping keys prefixed by 'errors:' from mapping object
            mapping.mappingKey = _.difference(mappingKeys, errorMappingKeysArr).join(',');
            _.merge(payload || {}, this.getMappingPayload(mapping));
          }
          this.trackErrors(payload || {}, errors, isModalPopup, selectedSections);
        } catch (error) {
          console.warn('trackFormError method: ', error);
        }
      }

    /**
     * @description To track error on api failure
     * @param errorCode: string,
     *        errorMessage: string,
     *        isModalPopup?: boolean, (default = false)
     *        selectedSections?: DataLayerPredefinedSection[], if not specified then used all 4 sections
     *        mapping?: IDataLayerMapping
     */
    private trackAPIError: (errorCode: string, errorMessage: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping) => void =
      (errorCode: string, errorMessage: string, isModalPopup?: boolean, selectedSections?: DataLayerPredefinedSection[], mapping?: IDataLayerMapping) => {
        var payload = {}, error, errors = [];
        try {
          if (mapping && mapping.mappingKey) {
            let mappingKeys = mapping.mappingKey.split(',');
            let errorMappingKeysArr: string[] = _.filter(mappingKeys, function(keyItem: string) {
              return keyItem.indexOf('errors:') > -1;
            });
            error = this.getErrorData(errorCode, errorMessage, '', errorMappingKeysArr);
            //remove error mapping keys prefixed by 'errors:' from mapping object
            mapping.mappingKey = _.difference(mappingKeys, errorMappingKeysArr).join(',');
            payload = this.getMappingPayload(mapping);
            errors.push(error);

            this.trackErrors(
              payload,
              errors,
              isModalPopup,
              selectedSections);
          }
        } catch (error) {
          console.warn('trackAPIError method: ', error);
        }
      }
    /**
     * @description To return an object with page name and hierarchy by parsing the hash value (i.e. location.hash)
     * @param hash: string
     * @returns pageObj: { name: string, hierarchy: string }
     */
    private parseHashValue(hash: string) {
      var hashValue,
        pageName,
        hashValueParts,
        hashValueArr,
        rootHierarchy,
        pageObj = {};

      if (_.has(this.config, 'advance.configOptions.rootHierarchy')) {
        rootHierarchy = this.config.advance.configOptions.rootHierarchy;
      }

      hashValueArr = hash.split('#/');
      if (hashValueArr.length > 1) {
        hashValue = hashValueArr[1]; // hashValueArr[0] will be empty string
        hashValueParts = hashValue.split('/').join('|').toLowerCase().split('|');
        pageName = hashValueParts[hashValueParts.length - 1];

        if (rootHierarchy && rootHierarchy.length > 0) {
          hashValueParts = rootHierarchy.concat(hashValueParts);
        }

        pageObj = {
          name: pageName,
          hierarchy: hashValueParts
        };
      }
      return pageObj;
    }

    /**
     * @description To return a error object by:
     *  1. Retrieving mapping values by mapping keys for category, type and API properties.
     *  2. Setting properties code, message, field using passed in params
     * @param errorCode: string
     * @param errorMessage: string
     * @param fieldName: string
     * @param keysArr: string[]; can contain one or more mapping key items in the following structure:
     *                           ['errors:key1:key2',errors:key3']
     * @returns error: IADLError
     */
    private getErrorData = (errorCode: string, errorMessage: string, fieldName: string, keysArr: string[]) => {
      let self = this;
      let errorData : IADLError = {
        code: errorCode,
        message: errorMessage,
        field: fieldName,
        type: '',
        category: '',
        API: ''
      };
      let mappingKeys = [], errorMappingKeysStr;
      _.forEach(keysArr, function (keyItem: string) {
        keyItem = _.trim(keyItem);
        //trim errors keyword in keyItem string from index 7 till end of the string: errors:key1:key2 => key1:key2
        errorMappingKeysStr = keyItem.substr(7);

        //concat mappingKeys array and errorMappingKeysStr.split(':')
        mappingKeys = mappingKeys.concat(errorMappingKeysStr.split(':'));
      });

      _.forEach(mappingKeys, function(mappingKey: string){
        if (_.has(self.config.advance.mappingKey, mappingKey)) {
          _.merge(errorData, _.cloneDeep(_.get(self.config.advance.mappingKey, mappingKey)));
        } else {
          console.warn('getErrorData => errorMappingKey: ' + mappingKey + ' is not defined in advance.mappingKey configuration property');
        }
      });

      return errorData;
    }
    /**
     * @description To return a page payload object by:
     *  1. Retrieving mapping value by mapping key from config object.
     *  2. Setting the page name and hierarchy by parsing the hash value if a mapping doesn't exist.
     * @param mapping: IDataLayerMapping
     * @returns payload: ITrackDataPayload
     */
    private getMappingPayload: (mapping: IDataLayerMapping) => ITrackDataPayload = (mapping: IDataLayerMapping) => {
      let payload: ITrackDataPayload = {};
      let _location = location.hash;
      let _stateURL = (mapping.stateURL) ? mapping.stateURL : '';
      let self = this;

      //Since mapping object is an optional parameter, few applications may not send this value. Adding null check to avoid the logical error
      if (mapping.mappingKey && mapping.mappingKey !== null && mapping.mappingKey !== undefined) {
        let mappingKeys = mapping.mappingKey.split(',');
        _.forEach(mappingKeys, function(mappingKey: string){
          mappingKey = _.trim(mappingKey);
          if (_.has(self.config.advance.mappingKey, mappingKey)) {
            _.merge(payload, _.cloneDeep(_.get(self.config.advance.mappingKey, mappingKey)));
          } else {
            console.warn('getMappingPayload => mappingKey: ' + mappingKey + ' is not defined in advance.mappingKey configuration property');
          }
        });
      }

      //Setting the page name and hierarchy by parsing the hash value if a mapping doesn't exist.
      if (Object.keys(payload).length === 0) {
        /* to handle cases when a parameter exists in an URL */
        if (_stateURL.indexOf('/:') > -1) {
          let key = _stateURL.split('/:')[0];
          _location = _location.substring(0, _location.indexOf(key) + key.length);
        }
        payload = {
          page: this.parseHashValue(_location)
        };
      }
      return payload;
    }

    /**
     * @description To return config object by:
     *  1. Retrieving mapping value by mapping key from config object.
     * @param mapping: IDataLayerMapping
     * @returns mappedObject: any
     */
    private getConfigByMappingKey = (mapping: IDataLayerMapping) => {
      let mappedObject: any;
      if (_.has(this.config, mapping.mappingKey)) {
        mappedObject = _.cloneDeep(_.get(this.config, mapping.mappingKey));
      }
      return mappedObject;
    }


    /**
     * @description To configure page payload object
     *
     */
    private getAccountPayloadData: () => ng.IPromise<ITrackDataPayload> = () => {
      let accountPayload: ITrackDataPayload;
      let accountData: IADLAccount;
      let wirelessData: IADLWireless;

      let currentAccount_cb = _.find(this.registeredCallbacks, {'callbackKey': 'account'});
      let currentUser_cb = _.find(this.registeredCallbacks, {'callbackKey': 'user'});
      let currentDevice_cb = _.find(this.registeredCallbacks, {'callbackKey': 'device'});
      let currentPlanInfo_cb = _.find(this.registeredCallbacks, {'callbackKey': 'plan'});

      let currentAccount_promise;
      let currentUser_promise;
      let currentDevice_promise;
      let currentPlanInfo_promise;

      if (currentAccount_cb) {
        currentAccount_promise = currentAccount_cb.callback();
      }

      if (currentUser_cb) {
        currentUser_promise = currentUser_cb.callback();
      }

      if (currentDevice_cb) {
        currentDevice_promise = currentDevice_cb.callback();
      }

      if (currentPlanInfo_cb) {
        currentPlanInfo_promise = currentPlanInfo_cb.callback();
      }

      return this.$q.all([
        currentAccount_promise,
        currentUser_promise,
        currentDevice_promise,
        currentPlanInfo_promise])
        .then((values) => {
          let accountObj = values[0];
          let userObj = values[1];
          let deviceObj = values[2];
          let planObj = values[3];

          if (accountObj || userObj) {
            accountData = {};
            if (accountObj) {
              if (accountObj.accountType) {
                accountData.type = accountObj.accountType.toLowerCase();
              }
              if (accountObj.accountNumber) {
                accountData.BAN = accountObj.accountNumber.toString();
              }
              if (accountObj.phoneNumber) {
                accountData.CTN = accountObj.phoneNumber.toString();
              }
            }
            if (userObj) {
              accountData.userType = userObj.userType;
              accountData.userName = userObj.userName;
            }

            if (deviceObj || planObj) {
              wirelessData = {};
              if (deviceObj) {
                wirelessData.device = deviceObj.device;
              }
              if (planObj) {
                wirelessData.plan = planObj.plan;
                wirelessData.balance = {
                  amount: planObj.amount,
                  payoffDate: planObj.payoffDate
                };
                accountData.wireless = wirelessData;
              }
            }
            accountPayload = {account: accountData};
          }
          return accountPayload;
        })
        .catch((error) => {
          console.warn('Error in configuring account section. Failed to load account/user details.');
          return accountPayload;
        });
    }

    /**
     * @description To configure page payload object
     *
     */
    private getPagePayloadData: (isModalPopup: boolean) => ng.IPromise<ITrackDataPayload> = (isModalPopup: boolean) => {
      let pagePayload: ITrackDataPayload;
      let pageData: IADLPage;
      let currentLanguage_cb = _.find(this.registeredCallbacks, {'callbackKey': 'language'});
      let currentProvince_cb = _.find(this.registeredCallbacks, {'callbackKey': 'province'});
      let currentLanguage_promise;
      let currentProvince_promise;

      if (currentLanguage_cb) {
        currentLanguage_promise = currentLanguage_cb.callback();
      }

      if (currentProvince_cb) {
        currentProvince_promise = currentProvince_cb.callback();
      }

      return this.$q.all([
        currentLanguage_promise,
        currentProvince_promise])
        .then((values) => {

          let languageResponse = values[0];
          let provinceResponse = values[1];
          pageData = {
            url: isModalPopup ? 'modal popup' : location.href,
            referrer: isModalPopup ? location.href : this.referrer,
            ICMTimestamp: moment().format('dddd|MM/DD/YYYY HH:mm'),
            responsiveVersion: this.isMobile() ? 'mobile' : 'desktop'
          };
          if (languageResponse) {
            pageData.language = languageResponse.language;
          }
          if (provinceResponse) {
            pageData.province = provinceResponse.province.toLowerCase();
          }
          this.referrer = location.href;
          return pagePayload = {page: pageData};
        })
        .catch((error) => {
          console.warn('Error in configuring page section. Failed to load language/province details.');
          return pagePayload;
        });
    }


    /**
     * @description To check if specified section is required for the data layer track.
     * @param selectedSections: DataLayerPredefinedSection[]
     */
    private selectionContains: (selectedSections: DataLayerPredefinedSection[], searchSection : DataLayerPredefinedSection) => boolean = (selectedSections: DataLayerPredefinedSection[], searchSection : DataLayerPredefinedSection) => {
      return (selectedSections.filter((item) => (item === searchSection)).length >= 1);
    }

    /**
     * @description To merge all the selected information to the payload object.
     * @param payload: ITrackDataPayload,
     *        selectedSections: DataLayerPredefinedSection[]
     */
    private mergeSelectedInformationSync: (payload: ITrackDataPayload, selectedSections: DataLayerPredefinedSection[]) => ITrackDataPayload =
      (payload: ITrackDataPayload, selectedSections: DataLayerPredefinedSection[]) => {
        try {
          /* Populate selected payload information */
          selectedSections.forEach((section) => {
            switch (section) {
              case DataLayerPredefinedSection.PAGE_VIEW_EVENT:
                _.merge(payload, { events: this.getPageViewEventObject() });
                break;
              case DataLayerPredefinedSection.SIGNIN:
                _.merge(payload, { signin: this.getSigninObject() });
                break;
              case DataLayerPredefinedSection.SITE:
                _.merge(payload, { site: this.getSiteObject() });
                break;
              default:
            }
          });
          return payload;
        } catch (error) {
          console.warn('Merge selected tracking data', error);
        }
      }

    /**
     * @description To merge all the selected information to the payload object.
     * @param payload: ITrackDataPayload,
     *        isModalPopup: boolean,
     *        selectedSections: DataLayerPredefinedSection[]
     *        mapping: IDataLayerMapping
     */
    private mergeSelectedInformation: (payload: ITrackDataPayload, isModalPopup: boolean, selectedSections: DataLayerPredefinedSection[]) => ng.IPromise<ITrackDataPayload> =
      (payload: ITrackDataPayload, isModalPopup: boolean, selectedSections: DataLayerPredefinedSection[]) => {
        let promises = [];
        if (this.selectionContains(selectedSections, DataLayerPredefinedSection.ACCOUNT)) {
          promises.push(this.getAccountPayloadData());
        }
        if (this.selectionContains(selectedSections, DataLayerPredefinedSection.PAGE)) {
          promises.push(this.getPagePayloadData(isModalPopup));
        }
        if (promises.length > 0) {
          return this.$q.all(promises)
            .then((payLoadData) => {
              payLoadData.forEach((_payLoadItem) => {
                if (_payLoadItem) {
                  _.merge(payload, _payLoadItem);
                }
              });
              _.merge(payload, this.mergeSelectedInformationSync(payload, selectedSections));
              return payload;
            }).catch((error) => {
              console.warn('Error in merging selected tracking data', error);
              return this.$q.reject(error);
            });
        } else {
          _.merge(payload, this.mergeSelectedInformationSync(payload, selectedSections));
          return this.$q.when(payload);
        }
      }

    /**
     * @description To get the data layer site object.
     */
    private getSiteObject: () => IADLSite = () => {
      let returnObject: IADLSite = {
        name: this.config.basic.site.name
      };
      return returnObject;
    }

    /**
     * @description To get the data layer signin object
     */
    private getSigninObject: () => IADLSignin = () => {
      let returnObject: IADLSignin = {
        type: this.config.basic.signin.type
      };
      return returnObject;
    }

    /**
     * @description To get the data layer event object
     */
    private getPageViewEventObject: () => IADLEvents = () => {
      let returnObject: IADLEvents = {
        pageView: true
      };
      return returnObject;
    }

    private isMobile: () => boolean = () => {
      return (this.isAndroid() || this.isBlackBerry() || this.isIOS() || this.isWindowsMobile());
    }

    private isWindowsMobile: () => boolean = () => {
      return /IEMobile/i.test(this.$window.navigator.userAgent);
    }

    private isAndroid: () => boolean = () => {
      return /Android/i.test(this.$window.navigator.userAgent);
    }

    private isBlackBerry: () => boolean = () => {
      return /BlackBerry/i.test(this.$window.navigator.userAgent);
    }

    private isIOS: () => boolean = () => {
      return /iPhone|iPad|iPod/i.test(this.$window.navigator.userAgent);
    }
  }
  angular.module('ute.datalayer.common').provider('ute.datalayer.common.service', DataLayerServiceProvider);
}
