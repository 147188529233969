namespace ute.datalayer.common.service {
  'use strict';

  interface IDataLayerAttributes extends ng.IAttributes {
    eventPayload: string;
    isModalPopup: string;
    eventName: string;
    mappingKey: string;
    pageView: string;
  }

  angular.module('ute.datalayer.common').directive('uteDataLayerEvent', UteDataLayerEvent);

  UteDataLayerEvent.$inject = ['ute.datalayer.common.service', '$parse'];

  /* @ngInject */
  function UteDataLayerEvent(dataLayerService: IDataLayerService, $parse: ng.IParseService) {
    return {
      restrict: 'A',
      link: function ($scope: ng.IScope, $element: ng.IRootElementService, attrs: IDataLayerAttributes): void {
        $scope.$applyAsync(() => {
          try {
            let mapping: IDataLayerMapping, payload, isModalPopup: boolean;
            let selectedSections: DataLayerPredefinedSection[] = [
              DataLayerPredefinedSection.ACCOUNT,
              DataLayerPredefinedSection.PAGE,
              DataLayerPredefinedSection.SITE];

            function setTrackParams() {
              var mappingKey;
              if (attrs.eventPayload) {
                // try parse json object . In case when payload specified as a json object.
                try {
                  payload = JSON.parse(attrs.eventPayload);
                } catch (e) {
                  //silent fail
                }

                // try parse bound variable ( scope or controller(as syntax)) when json parsing failed.
                if (!payload) {
                  try {
                    payload = $parse(attrs.eventPayload)($scope);
                  } catch (e) {
                    //silent fail
                  }
                }
              }

              if (!(attrs.isModalPopup === 'true')) {
                isModalPopup = false;
              } else {
                isModalPopup = true;
              }

              try {
                mappingKey = $parse(attrs.mappingKey)($scope);
              } catch (e) {
                //silent fail
              }
              if (!mappingKey) {
                mappingKey = attrs.mappingKey;
              }

              if (mappingKey) {
                mapping = {
                  mappingKey: mappingKey
                };
              }

              if (attrs.pageView === 'true') {
                selectedSections.push(DataLayerPredefinedSection.PAGE_VIEW_EVENT);
              }
            }

            $element.on(attrs.eventName, (event) => {
              setTrackParams();
              dataLayerService.track(
                payload || {},
                isModalPopup,
                selectedSections,
                mapping);
            });

          } catch (err) {
            console.warn('Exception in dataLayer form event tracking directive', err);
          }
        });
      }
    };
  }
}

